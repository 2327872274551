import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fixAmountFormat, getLocalTimezone, handleKeyDown, isEveryValueNull, planIdMap } from "../../../../utils";
import { Card, Col, Radio, Row, Button, Spin, Empty } from "antd";
import moment from "moment";
import { SmileOutlined } from '@ant-design/icons';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined
} from '@ant-design/icons'
import loaderLogo from "../../../../assets/img/incomm-loader.svg"
import { DATE_FORMAT, transactionsFilterMap } from "../../../../utils/constants";
import { Typography, Table, ExpandIcon, Button as DesignButton } from "../../../../design-systems";
import { ExpandedModalView } from "./ExpandedViewModal";
import DatePicker2 from "../../../../design-systems/DatePicker2";
import { useDispatch } from "react-redux";
import { getTransactionsByZAN } from "../../../../redux/actions";


const renderTransactionFilters = (mode, handleModeChange, open, setOpen, modalLoading, showDepositAndWithdrawl) => (
  <div className="d-flex align-items-center justify-space-between gap-15 mr-10">
    <Radio.Group
      onChange={handleModeChange}
      value={mode}
      defaultValue="distributions"
      name="transaction-type"
      className="transactions-radio"
    >
      <Radio.Button className="distributions" value="distributions">{showDepositAndWithdrawl ? "Distributions" : "Withdrawals"}</Radio.Button>
      <Radio.Button className="contributions" value="contributions">{showDepositAndWithdrawl ? "Contributions" : "Deposits"}</Radio.Button>
    </Radio.Group>
    {
      !open &&
      <div disabled={modalLoading} onClick={() => setOpen(true)}>
        <ExpandIcon />
      </div>
    }
  </div>
)
const renderTransactionDetails = (
  filter,
  handleModeChange,
  mode,
  modalMode,
  handleFilterChange,
  filtersList,
  transactionColumns,
  data,
  setOpen,
  open,
  applySearchFilter,
  search,
  setSearch,
  limit,
  isModalOpen,
  setModalCurrentPage,
  modalCurrentPage,
  modalFilter,
  transactions,
  modalTransactions,
  dateRange,
  setDateRange,
  customLoader,
  modalLoading,
  loading,
  handleNextPage,
  setActiveFilter,
  showDepositAndWithdrawl
) => {
  const getTableDataSource = () => {
    const filterTransactionsByMode = (transactionsList) => {
      const currentMode = open ? modalMode : mode;
      return transactionsList.filter((transaction) => {
        if (currentMode === 'contributions') {
          return (
            (transaction.heading?.toLowerCase()?.includes('contribution') && !transaction.sub_heading?.toLowerCase()?.includes('from everyday')) ||
            (transaction.heading?.toLowerCase()?.includes('deposit') || transaction.sub_heading?.toLowerCase()?.includes('to everyday')) ||
            transaction.sub_heading?.toLowerCase()?.includes('to hsa')
          );
        } else {
          return !(
            (transaction.heading?.toLowerCase()?.includes('contribution') && !transaction.sub_heading?.toLowerCase()?.includes('from everyday')) ||
            (transaction.heading?.toLowerCase()?.includes('deposit') || transaction.sub_heading?.toLowerCase()?.includes('to everyday')) ||
            transaction.sub_heading?.toLowerCase()?.includes('to hsa')
          )
        }
      });
    };
    if (isModalOpen) {
      return filterTransactionsByMode(modalTransactions[modalFilter]);
    } else {
      return filterTransactionsByMode(transactions[filter]).slice(0, 5)
    }

  }

  return (
    <Card bordered={false} className={!open ? "account-holder-cards" : ""}>
      <div className={`d-flex flex-wrap ${open ? "align-items-center justify-flex-end" : "align-items-center justify-space-between"}`}>
        {!open && <Typography
          variant="subhead-3"
          label={"Transactions"}
          className="employer-name"
        />}
        {!open && renderTransactionFilters(isModalOpen ? modalMode : mode, handleModeChange, open, setOpen, modalLoading, showDepositAndWithdrawl)}
      </div>
      {/* render the Datepicker  */}
      {open &&
        <Row gutter={[20]}>
          <Col sm={24}>
            <div style={{ height: 40 }} className="account-activity-datepicker-wrapper">
              <div style={{ marginLeft: 5, marginTop: -4, maxWidth: "100%", width: "100%", }}>
                <DatePicker2
                  maxDate={new Date()}
                  className="transactions-datepicker"
                  customClassName="transactions-datepicker"
                  readOnly={false}
                  closeOnScroll={(e) => e.target === document}
                  selectsRange={true}
                  showImportant={false}
                  placeholder="Start date - End date"
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  format={['MM/dd/yyyy', 'M/d/yyyy']}
                  onChange={(dates) => {
                    setDateRange(dates);
                    setActiveFilter("dateRange")
                  }}

                  onKeyDown={handleKeyDown}
                  isClearable={true}
                />
              </div>
            </div>
          </Col>
        </Row>
      }
      <div className="d-flex align-items-center justify-space-between gap-30 mt-20">
        <Radio.Group
          onChange={handleFilterChange}
          value={isModalOpen ? modalFilter : filter}
          name="transaction-filters"
          defaultValue="All"
          className="transactions-radio d-flex align-items-center  gap-30 flex-wrap"
        >
          {
            filtersList && filtersList?.map(filter => (
              <Radio.Button className="transactions-filters" value={filter}>{filter}</Radio.Button>
            ))
          }
        </Radio.Group>
      </div>
      {/* render the modal table */}
      {open && <div className="mt-30">
        {getTableDataSource()?.length && !modalLoading ? <Table
          bordered
          columns={transactionColumns}
          dataSource={getTableDataSource()}
          scroll={
            data?.length ? { x: 1200 } : undefined
          }
          pagination={false}
          tableLayout="auto"
          className="compact-table mb-15 expenses-section-table activity-logs-table"
        /> :
          !modalLoading ? <Empty /> : customLoader
        }
        <div className="d-flex justify-flex-end">
          <div>
            <Button
              disabled={modalLoading || modalCurrentPage <= 1}
              variant="ghost"
              onClick={() => {
                if (modalCurrentPage > 1) {
                  setModalCurrentPage(prev => prev - 1);
                }
              }}
              loading={modalLoading}
              style={{ borderRadius: 25, background: "#f6f8f9" }}
            >
              <ArrowLeftOutlined />
              Previous
            </Button>
          </div>
          <Button
            disabled={modalLoading || modalTransactions[modalFilter]?.length < limit}
            variant="ghost"
            loading={modalLoading}
            onClick={handleNextPage}
            style={{ borderRadius: 25, background: "#f6f8f9" }}
          >
            Next
            <ArrowRightOutlined />
          </Button>
        </div>
      </div>
      }
      {/* render the normal view table */}
      {!open && <div className="mt-30">
        {getTableDataSource()?.length && !loading ? <Table
          bordered
          columns={transactionColumns}
          dataSource={getTableDataSource()}
          scroll={
            data?.length ? { x: 1200 } : undefined
          }
          pagination={false}
          tableLayout="auto"
          className="compact-table mb-15 expenses-section-table activity-logs-table"
        /> :
          !loading ? <Empty /> : customLoader
        }
      </div>
      }
    </Card>
  )
}
function TransactionsSection({ data, accountBalance, zan }) {
  const dispatch = useDispatch()
  const [dateRange, setDateRange] = useState([null, null]);
  const [filtersList, setFiltersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [transactions, setTransactions] = useState({
    "Everyday": [],
    "HSA": [],
    "FSA": [],
    "LFSA": [],
    "DCFSA": [],
    "All": [],
    "TABLE_DATA": []
  });

  const [modalTransactions, setModalTransactions] = useState({
    "Everyday": [],
    "HSA": [],
    "FSA": [],
    "LFSA": [],
    "DCFSA": [],
    "All": [],
    "TABLE_DATA": []
  });
  const [activeFilter, setActiveFilter] = useState(null)
  const [mode, setMode] = useState('distributions');
  const [modalMode, setModalMode] = useState('distributions');
  const [filter, setFilter] = useState('All');
  const [modalFilter, setModalFilter] = useState('All');
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")

  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [limit] = useState(25)

  const handleModeChange = (e) => {
    open ? setModalMode(e.target.value) : setMode(e.target.value);
  };

  const handleFilterChange = (e) => {
    open ? setModalFilter(e.target.value) : setFilter(e.target.value);
  };

  const getTransactionsForAll = (addPage = false) => {
    getTransactionsByFilter(open ? modalFilter : filter, addPage)
  }

  // main function to call the api for transaction with category
  const getTransactionsByFilter = (key, addPage) => {
    if (zan) {
      open ? setModalLoading(true) : setLoading(true);
      const apiData = { limit, page: modalCurrentPage };
      if (key !== "All") {
        apiData["type"] = key
      }
      if (!isEveryValueNull(dateRange)) {
        apiData.from_ts = dateRange[0] ? `${moment(dateRange[0]).format(DATE_FORMAT.FOR_SERVER)} 00:00:00` : "";
        apiData.to_ts = dateRange[1] ? `${moment(dateRange[1]).format(DATE_FORMAT.FOR_SERVER)} 23:59:59` : "";
      }
      dispatch(getTransactionsByZAN(zan, apiData, (res => {
        if (!(res instanceof Error)) {
          open ? setModalLoading(false) : setLoading(false);
          const exp = !open ? { ...transactions } : { ...modalTransactions };
          exp[key] = res?.transactions.map((t, index) => ({
            key: index + 1,
            ...t
          })) ?? [];
          open ? setModalTransactions(exp) : setTransactions(exp);
        }
      })))
    }
  }

  // we are updating the current page and calling the api, unless currentpage is already 1 
  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      if (modalCurrentPage === 1) {
        getTransactionsForAll();
      } else {
        setModalCurrentPage(1)
      }
    }
    if (isEveryValueNull(dateRange)) {
      if (modalCurrentPage === 1) {
        getTransactionsForAll();
      } else {
        setModalCurrentPage(1)
      }
    }
  }, [dateRange])

  // if any filter change we set the current page to 1
  useEffect(() => {
    setModalCurrentPage(1)
  }, [accountBalance, zan, modalFilter, filter])

  // call the transactions api if blow value changes
  useEffect(() => {
    if (open) {
      setModalLoading(true)
    }
    setModalCurrentPage(1)
    if (modalCurrentPage === 1) {
      getTransactionsForAll(true)
    }
  }, [accountBalance, zan, modalFilter, filter, open]);

  useEffect(() => {
    getTransactionsForAll(true)
  }, [modalCurrentPage])

  // this set the filters from the keys using account balance 
  useEffect(() => {
    if (accountBalance !== null) {
      setFiltersList(["All", ...Object.keys(accountBalance)]);
    }
  }, [accountBalance])

  // this sets the default value of modalCurrent page and modal filter
  useEffect(() => {
    if (open) {
      setModalCurrentPage(currentPage);
      setModalFilter(filter);

      setModalTransactions(transactions);
    }
  }, [open])

  const renderChargesData = (charges) => {
    if (Array.isArray(charges)) {
      return (
        <>
          <Row>
            <Col xs={12}>
              <b>Account type</b>
            </Col>
            <Col xs={12} align="right">
              <b className="align-right">Charge</b>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            {
              charges?.map(charge => (
                <>
                  <Col xs={12}>
                    <div className="d-flex gap-10">
                      <div>
                        {planIdMap[charge?.account_type]} {charge.plan_start_date ? moment(charge.plan_start_date).format("YYYY") : null}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="align-right">{fixAmountFormat(charge?.charge)}</div>
                  </Col>
                </>
              ))
            }
          </Row>
        </>
      )
    } else {
      return null;
    }
  }

  const transactionDistributionColumns = [
    {
      title: <div className="big-columns">Txn timestamp</div>,
      dataIndex: "txn_timestamp",
      render: (date) => date ?
        `${moment
          .utc(date)
          .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      render: (amount) => fixAmountFormat(amount)
    },
    {
      title: "Heading/Merchant",
      dataIndex: "heading",
    },
    {
      title: "Subheading",
      dataIndex: "sub_heading",
    },
    {
      title: <div className="big-columns">Travel expense status</div>,
      dataIndex: "travel_eligibility_status",
    },
    {
      title: "Classification",
      dataIndex: "classification",
    },
    {
      title: "Charges",
      dataIndex: "charges",
      className: "charges_column",
      render: (charges) => renderChargesData(charges)
    },
    {
      title: <div className="big-columns">Eligible amount</div>,
      dataIndex: "eligible_amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Mode",
      dataIndex: "mode",
    },
    {
      title: "Type",
      dataIndex: "channel"
    },
    {
      title: <div className="big-columns">InComm Benefits guarantee</div>,
      dataIndex: "zenda_guarantee",
      render: (zenda_guarantee) => zenda_guarantee ? "True" : "False"
    },
    {
      title: <div className="big-columns">Health expense status</div>,
      dataIndex: "health_expense_status",
    },

    {
      title: <div className="big-columns">Merchant category code</div>,
      dataIndex: "merchant_category_code",
    },
    {
      title: <div className="big-columns">Auth timestamp</div>,
      dataIndex: "auth_timestamp",
      render: (date) => date ? `${moment
        .utc(date)
        .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: <div className="big-columns">Settle timestamp</div>,
      dataIndex: "settle_timestamp",
      render: (date) => date ? `${moment
        .utc(date)
        .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: <div className="big-columns">Transaction ID</div>,
      dataIndex: "transaction_id",
    },
    {
      title: <div className="big-columns">Expense ID</div>,
      dataIndex: "expense_id",
    },
    {
      title: "TXN for",
      dataIndex: "txn_for",
    },
  ]

  const transactionContributionColumns = [
    {
      title: <div className="big-columns">Txn timestamp</div>,
      dataIndex: "txn_timestamp",
      render: (date) => date ?
        `${moment
          .utc(date)
          .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      render: (amount) => fixAmountFormat(amount)
    },
    {
      title: "Employee Contribution",
      dataIndex: "employee_contribution",
      align: "right",
      render: (amount) => fixAmountFormat(amount)
    },
    {
      title: "Employer Contributions",
      dataIndex: "employer_contribution",
      align: "right",
      render: (amount) => fixAmountFormat(amount)
    },
    {
      title: "Heading/Merchant",
      dataIndex: "heading",
    },
    {
      title: "Subheading",
      dataIndex: "sub_heading",
    },
    {
      title: <div className="big-columns">Transaction ID</div>,
      dataIndex: "transaction_id",
    },
  ]
  // for switching the columns in case mode change for both views 
  const activeColumns = useMemo(() => {
    const currentMode = open ? modalMode : mode;
    if (currentMode === "distributions") {
      return transactionDistributionColumns
    } else {
      return transactionContributionColumns
    }
  }, [modalMode, mode])

  const applySearchFilter = (value) => {
    setSearch(value)
  };
  // Will be used if we have search field introduced back
  useEffect(() => {
    if (activeFilter === 'search') {
      setDateRange([null, null]);
    } else if (activeFilter === 'dateRange') {
      setSearch("");
    }
  }, [activeFilter]);

  // will be called if we have search field and value is changes 
  useEffect(() => {
    if (search?.length) {
      let updatedTransactions = { ...modalTransactions };
      updatedTransactions["TABLE_DATA"] = updatedTransactions[modalFilter].filter(item => {
        return (
          item?.sub_heading?.toLowerCase().includes(search.toLowerCase()) ||
          item?.heading?.toLowerCase().includes(search.toLowerCase()) ||
          item?.merchant_name?.toLowerCase().includes(search.toLowerCase())
        )
      });
      setModalTransactions(updatedTransactions);
    } else {
      if (isEveryValueNull(dateRange)) {
        getTransactionsForAll()
      }
    }
  }, [search])

  const handleNextPage = () => {
    setModalCurrentPage(modalCurrentPage + 1);
  };

  const customLoader = (
    <Spin
      spinning={open ? modalLoading : loading}
      tip={<img src={loaderLogo} alt="Incomm Benefits-logo" className="loader-logo" />}
      className="page-loader-expense"
      size="large"
    >
    </Spin>
  )

  // to change the label of mode in case of HSA and DCFSA
  const depositAndWithdrawlPlans = ["HSA", "DCFSA"];
  const showDepositAndWithdrawl = useMemo(() => {
    const currentFilter = open ? modalFilter : filter;
    return filter && depositAndWithdrawlPlans.includes(currentFilter)
  }, [filter, modalFilter]);

  return (
    <>
      {renderTransactionDetails(
        filter,
        handleModeChange,
        mode,
        modalMode,
        handleFilterChange,
        filtersList,
        activeColumns,
        data,
        setOpen,
        false,
        applySearchFilter,
        search,
        setSearch,
        limit,
        false,
        setModalCurrentPage,
        modalCurrentPage,
        modalFilter,
        transactions,
        modalTransactions,
        dateRange,
        setDateRange,
        customLoader,
        modalLoading,
        loading,
        handleNextPage,
        setActiveFilter,
        showDepositAndWithdrawl
      )}

      <ExpandedModalView
        open={open}
        setOpen={setOpen}
        title={"Transactions"}
        afterClose={() => {
          setModalCurrentPage(1);
          setSearch("")
          setDateRange([null, null])
        }}
        renderFilters={() => renderTransactionFilters(open ? modalMode : mode, handleModeChange, open, setOpen, modalLoading, showDepositAndWithdrawl)}
        renderJSX={() => renderTransactionDetails(
          filter,
          handleModeChange,
          mode,
          modalMode,
          handleFilterChange,
          filtersList,
          activeColumns,
          data,
          setOpen,
          true,
          applySearchFilter,
          search,
          setSearch,
          limit,
          true,
          setModalCurrentPage,
          modalCurrentPage,
          modalFilter,
          transactions,
          modalTransactions,
          dateRange,
          setDateRange,
          customLoader,
          modalLoading,
          loading,
          handleNextPage,
          setActiveFilter,
          showDepositAndWithdrawl
        )}
      />
    </>
  );
}

export { TransactionsSection };
